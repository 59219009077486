
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {CodeDescriptionRestDto, TitleMetaDataRestDto} from "@/api/pharma-cms-content-block/models";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {ImageTargetEnum} from "@/api/enums/image-target-enum";
import {TextWithImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithImageContentRestDtoModel";
import {ButtonTargetEnum} from "@/api/enums/button-target-enum";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {ButtonRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonRestDtoModel";
import {ImageRestDtoModel} from "@/models/api/pharma-cms-content-block/ImageRestDtoModel";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import Subtitle from "@/components/UI/Subtitle.vue";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputText from "@/components/UI/InputText.vue";
import InputFile from "@/components/UI/InputFile.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";
import AdvancedPanelSwitcher from "@/components/UI/AdvancedPanelSwitcher.vue";
import {TitleMetaDataRestDtoModel} from "@/models/api/pharma-cms-content-block/TitleMetaDataRestDtoModel";
import TitleMetaInputComponent from "@/components/UI/TitleMetaInputComponent.vue";
import AdvancedPanel from "@/components/UI/AdvancedPanel.vue";

export default defineComponent({
  name: "CbTextWithImage",
  extends: AbstractCbCommon,
  components: {
    AdvancedPanelSwitcher,
    AdvancedPanel, TitleMetaInputComponent,
    InputTextEditor,
    InputFile,
    InputText, InputCheckboxBoolean, InputSelect, BaseSpinner, Subtitle, PageLinkPicker
  },
  props: {
    contentToolbar: {
      type: String,
      required: false,
    },
    editorOptions: {
      type: Object,
      required: false,
    },
    isAdvancedOptionsAvailable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    contentBlock: TextWithImageContentRestDtoModel.createWithDefaults(),
    pageLinkButtonPayload: PageLinkPayload.createWithDefaults(),
    pageLinkImagePayload: PageLinkPayload.createWithDefaults(),
    docType: 'PAGE_IMG' as string,
    useButton: false as boolean,
    removedButton: ButtonRestDtoModel.createWithDefaults(),

    imagePreviewUrl: null as string | null,
    isAdvancedTitleMeta: false,
  }),
  watch: {
    contentBlock() {
      this.buildPageLinkPayload();

      if (this.contentBlock.button) {
        this.useButton = true;
        this.removedButton = this.contentBlock.button;
      } else {
        this.useButton = false;
        this.contentBlock.button = undefined;
        this.removedButton = ButtonRestDtoModel.createWithDefaults();
      }
      if (!this.contentBlock.image) {
        this.contentBlock.image = ImageRestDtoModel.createWithDefaults()
      }

      if (this.existingDocumentKey) {
        CmsDocumentRestService.getInstance()
          .findPreviewUrl(this.existingDocumentKey).then((url: string) => {
          this.imagePreviewUrl = url;
        });
      } else {
        this.imagePreviewUrl = null;
      }

      this.onCopyCpAbstractChanged();
    },
    useButton(newVal) {
      if (newVal === true) {
        this.contentBlock.button = this.removedButton;

        this.buildPageLinkButtonPayload();
      } else {
        if (this.contentBlock.button !== undefined) {
          this.removedButton = this.contentBlock.button;
        }
        this.contentBlock.button = undefined
      }
    },
  },
  computed: {
    enumButtonLayoutCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BUTTON_LAYOUT);
    },
    enumBorderTypeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BORDER_TYPE);
    },
    enumBorderWidthCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BORDER_WIDTH);
    },
    enumHorizontalImageShift(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.HORIZONTAL_IMAGE_SHIFT);
    },
    enumImageWidthCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.IMAGE_WIDTH);
    },
    enumLightboxLayoutCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.LIGHTBOX);
    },
    enumObjectHorizontalPosition(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION);
    },
    enumLayoutCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TEXT_WITH_IMAGE_LAYOUT);
    },
    enumTitleSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TITLE_SIZE);
    },
    enumVerticalImageShift(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.VERTICAL_IMAGE_SHIFT);
    },
    existingDocumentKey(): string | null {
      return this.contentBlock.image?.image_key?.nl ?? null;
    },
    isArticleHeading(): boolean {
      return this.cbType === ContentBlockTypesEnum.ARTICLE_HEADING;
    },
    isOverview(): boolean {
      return this.cbType === ContentBlockTypesEnum.OVERVIEW_INFO;
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/text_with_image', ['create', 'find', 'save']),
    toggleAdvancedTitleMeta(): void {
      if (!this.isAdvancedTitleMeta && (this.contentBlock.title_meta === null || this.contentBlock.title_meta === undefined)) {
        this.contentBlock.title_meta = TitleMetaDataRestDtoModel.createWithDefaults();
      }
      this.isAdvancedTitleMeta = !this.isAdvancedTitleMeta;
    },
    onTitleMetaUpdate(payload: TitleMetaDataRestDto): void {
      this.contentBlock.title_meta = payload;
      this.onChange();
    },
    async beforeSubmitData(): Promise<void> {
      if (this.contentBlock !== null && this.contentBlock.title_meta !== null && this.contentBlock.title_meta !== undefined
        && this.contentBlock.title_meta.font_size_in_rem === null
        && this.contentBlock.title_meta.font_weight_code === null
        && this.contentBlock.title_meta.text_horizontal_position_code === null
        && this.contentBlock.title_meta.text_color_code === null
        && this.contentBlock.title_meta.text_color === null) {
        this.contentBlock.title_meta = null;
      }
    },
    beforeReloadContent() {
      if (this.isOverview) {
        this.bus.on('openPageAbstract', this.reloadContent);
      }
    },
    afterReloadContent() {
      if (this.contentBlock.title_meta) {
        this.isAdvancedTitleMeta = true;
      }
      if (this.contentBlock.button) {
        this.useButton = true;
        this.removedButton = this.contentBlock.button;
      }
      if (!this.contentBlock.image) {
        this.contentBlock.image = ImageRestDtoModel.createWithDefaults()
      }
    },
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.BUTTON_LAYOUT,
        EnumClassNameCmsEnum.BORDER_TYPE,
        EnumClassNameCmsEnum.BORDER_WIDTH,
        EnumClassNameCmsEnum.HORIZONTAL_IMAGE_SHIFT,
        EnumClassNameCmsEnum.IMAGE_WIDTH,
        EnumClassNameCmsEnum.LIGHTBOX,
        EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION,
        EnumClassNameCmsEnum.TEXT_WITH_IMAGE_LAYOUT,
        EnumClassNameCmsEnum.TITLE_SIZE,
        EnumClassNameCmsEnum.VERTICAL_IMAGE_SHIFT,
      ];
    },
    getDefaultCbModel(): TextWithImageContentRestDtoModel {
      return TextWithImageContentRestDtoModel.createWithDefaults();
    },
    buildPageLinkPayload(): void {
      this.buildPageLinkButtonPayload();
      this.buildPageLinkImagePayload();
    },
    buildPageLinkButtonPayload(): void {
      const externalUrlButton = this.contentBlock.button?.button_url?.nl ?? null;
      const pageIdButton = this.contentBlock.button?.button_page_id?.nl ?? null;
      const targetButton = this.contentBlock.button?.button_target ?? LinkTargetEnum.SELF;

      this.pageLinkButtonPayload = new PageLinkPayload(externalUrlButton, pageIdButton, targetButton.toString() as LinkTargetEnum);
    },
    buildPageLinkImagePayload(): void {
      const externalUrlImage = this.contentBlock.image?.image_url?.nl ?? null;
      const pageIdImage = this.contentBlock.image?.image_page_id?.nl ?? null;
      const targetImage = this.contentBlock.image?.image_target ?? LinkTargetEnum.SELF;

      this.pageLinkImagePayload = new PageLinkPayload(externalUrlImage, pageIdImage, targetImage.toString() as LinkTargetEnum);
    },
    onCopyCpAbstractChanged(): void {
      if (!this.isOverview) {
        const title = this.contentBlock.title?.nl ?? null;
        const content = this.contentBlock.content?.nl ?? null;
        const image_key = this.contentBlock?.image?.image_key?.nl ?? null;
        this.copyCpAbstractPayload = new CopyCpAbstractPayload(content, image_key, title);

        this.$emit('abstractContentChanged', this.copyCpAbstractPayload);
      }
    },
    onPageLinkPickerButtonChanged(payload: PageLinkPayload) {
      if (this.contentBlock.button) {
        if (payload.type === PageLinkTypeEnum.NONE) {
          this.contentBlock.button.button_url = MultilingualRestDtoModel.createWithDefaults();
          this.contentBlock.button.button_page_id = undefined;
          this.contentBlock.button.button_target = ButtonTargetEnum.SELF;
        } else if (payload.type === PageLinkTypeEnum.URL) {
          this.contentBlock.button.button_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
          this.contentBlock.button.button_page_id = undefined;
          this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
        } else if (payload.type === PageLinkTypeEnum.PAGE) {
          this.contentBlock.button.button_url = MultilingualRestDtoModel.createWithDefaults();
          this.contentBlock.button.button_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
          this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
        }
      }

      this.onChange();
    },
    onPageLinkPickerImageChanged(payload: PageLinkPayload) {
      if (this.contentBlock.image) {
        if (payload.type === PageLinkTypeEnum.NONE) {
          this.contentBlock.image.image_url = undefined;
          this.contentBlock.image.image_page_id = undefined;
          this.contentBlock.image.image_target = undefined;
        } else if (payload.type === PageLinkTypeEnum.URL) {
          this.contentBlock.image.image_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
          this.contentBlock.image.image_page_id = undefined;
          this.contentBlock.image.image_target = payload.target.toString() as ImageTargetEnum;
        } else if (payload.type === PageLinkTypeEnum.PAGE) {
          this.contentBlock.image.image_url = undefined;
          this.contentBlock.image.image_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
          this.contentBlock.image.image_target = payload.target.toString() as ImageTargetEnum;
        }
      }

      this.onChange();
    },
    async setImageKey(key: string | null): Promise<void> {
      if (this.contentBlock.image) {
        this.contentBlock.image.image_key = new MultilingualRestDtoModel(null, null, null, key);
      }

      if (key) {
        this.contentBlock.image_key = new MultilingualRestDtoModel(null, null, null, key);
      } else {
        this.contentBlock.image_key = undefined;
      }

      if (key !== null) {
        this.imagePreviewUrl = await CmsDocumentRestService.getInstance().findPreviewUrl(key);
      } else {
        this.imagePreviewUrl = null;
      }

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = TextWithImageContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as TextWithImageContentRestDtoModel);
      this.contentBlock = cbClone;

      this.buildPageLinkPayload();
    },
  }
});
