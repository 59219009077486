import {RestService} from "@/services/rest/RestService";
import {NvpAPIApi} from "@/api/pharma-master-nvp";
import {DpNameValuePairDetailRestDto, DpNameValuePairValueRestDto} from "@/api/pharma-master-nvp/models";

export class NVPRestService extends RestService {

    private nvpService: NvpAPIApi;

    protected constructor() {
        super();

        this.nvpService = new NvpAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().masterUrl);
    }

    public async getDpNvpByName(x_dp_customer_code: string, name: string, x_dp_module_code?: string | null): Promise<DpNameValuePairDetailRestDto> {
        const response =
            await this.nvpService.getDpNvpByName(x_dp_customer_code, name, null, x_dp_module_code);

        return response.data;
    }

    public async findDpNvpByName(x_dp_customer_code: string, name: string, x_dp_module_code?: string | null): Promise<DpNameValuePairDetailRestDto | null> {
        try {
            return await this.getDpNvpByName(x_dp_customer_code, name, x_dp_module_code);
        } catch (error: any) {
            if (error.response?.status === 404) {
                console.error('Status 404 catched returning null');
                return null;
            }
            throw error;
        }
    }

    public async updateDpNvpByName(body: DpNameValuePairValueRestDto, x_dp_customer_code: string, name: string, x_dp_module_code?: string|null): Promise<void> {
        await this.nvpService.updateDpNvp_1(body, x_dp_customer_code, name, null, x_dp_module_code);
    }
}
