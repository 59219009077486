
import {defineComponent} from "vue";

export default defineComponent({
  name: "InputCheckboxBoolean",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    errors: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
    },
    checkboxLook: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    model: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean | undefined) {
        if (typeof value === 'undefined') {
          value = false;
        }
        this.$emit('update:modelValue', value);
      }
    },
    className(): string {
      const result = 'form-check mb-1';
      if (this.checkboxLook) {
        return result;
      }

      return result + ' form-switch';
    }
  },
});
