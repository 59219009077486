/*
* Checks if a number contains content
* Receives a possible number
* Returns a boolean
*/
function numberHasContent(num?: number | null): boolean {
    return (typeof num !== 'undefined' && num !== undefined && num !== null && num > 0);
}

/*
* Parses a number that needs to be saved
*
* Receives a number or a null value
* Returns a number or a null value
*/
function parseSavedNumber(number: number | null): number | null {
    if (number !== null && number >= 0) {
        return number + 0.001;
    } else {
        return null;
    }
}

/*
* Parses a number that needs to be saved
*
* Receives a number value
* Returns a number value
*/
function parseSavedRequiredNumber(number: number): number {
    return number + 0.001;
}

/*
* Parses a number that was searched
*
* Receives a number
* Returns a number
*/
function parseSearchedNumber(number: number): number {
    return parseFloat(number.toFixed(2));
}

/*
* Trims leading zeroes of a numeric string.
*/
function trimLeadingZeroes(str: string): string {
    return String(Number(str));
}


export {numberHasContent, parseSavedNumber, parseSavedRequiredNumber, parseSearchedNumber, trimLeadingZeroes};
