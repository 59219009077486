import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_2 = { class: "row align-items-center pt-3 border-top border-1 border-black border-opacity-25" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_CbTextWithImage = _resolveComponent("CbTextWithImage")!
  const _component_AutoSaveFeedback = _resolveComponent("AutoSaveFeedback")!
  const _component_base_offcanvas_button_close = _resolveComponent("base-offcanvas-button-close")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_alert_error, {
      exceptions: this.exceptions,
      class: "mb-4"
    }, null, 8, ["exceptions"]),
    _createElementVNode("div", _hoisted_1, [
      (this.isReady)
        ? (_openBlock(), _createBlock(_component_CbTextWithImage, {
            key: 0,
            "cb-id": this.contentPage.cp.overview_cb_id ?? undefined,
            "cb-type": this.contentBlockOverviewType,
            bus: this.bus,
            "content-toolbar": "undo redo | bold italic underline strikethrough | superscript subscript | removeformat",
            "editor-options": {
        forced_root_block: false,
        newline_behavior: 'linebreak',
        valid_elements: 'br,span[class],s,sup,sub',
      },
            "is-advanced-options-available": false,
            onSaved: this.afterSaveContent,
            onExceptions: this.setExceptions,
            onHasUnsavedChanges: this.setHasUnsavedChanges
          }, null, 8, ["cb-id", "cb-type", "bus", "onSaved", "onExceptions", "onHasUnsavedChanges"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (this.save && this.save(...args)), ["prevent"])),
            class: "btn btn-primary"
          }, _toDisplayString(_ctx.$t('save')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AutoSaveFeedback, {
            status: this.autoSaveFeedbackStatus
          }, null, 8, ["status"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_base_offcanvas_button_close, { class: "btn btn-light" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('close')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ], 64))
}