
import {defineComponent} from 'vue'
import ItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";

export default defineComponent({
  name: "ProductSelectorLine",
  components: {InputCheckboxBoolean, ItemIcons},
  emits: ['selectProduct', 'checkProduct'],
  props: {
    product: {
      type: [CatalogProductOverviewRestDtoModel, Object],
      required: true
    },
    singleProduct: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: false,
    }
  },
  computed: {},
  methods: {
    checkboxSelected(): void {
      this.$emit("checkProduct", {
        product: this.product,
        selected: this.selected
      });
    },
    selectProduct(): void {
      if (this.singleProduct) {
        this.$emit('selectProduct', this.product);
      } else {
        this.selected = !this.selected;
        this.checkboxSelected();
      }
    },
  }
})
