
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {AuthContext} from "@/context/AuthContext";
import {mapActions, mapState} from "pinia";
import {useClipboardStore} from "@/stores/ClipboardStore";
import {useToast} from "vue-toastification";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {DpNameValuePairValueRestDtoModel} from "@/models/api/pharma-master/DpNameValuePairValueRestDtoModel";
import {CustomerContext} from "@/context/CustomerContext";
import {NVPRestService} from "@/services/rest/master/NVPRestService";
import {DpModuleEnum} from "@/api/enums/dp-module-enum";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";

export default defineComponent({
  name: "ProductOrderImageBasedDetail",
  components: {CatalogBuildStatus, InputCheckboxBoolean, BaseSpinner, AlertError2, BaseTitle},
  data: () => ({
    toast: useToast(),

    isForceNonMedicineProductsToBack: false,
    isForceMedicineProductsToBack: false,

    NON_MED_NVP_NAME: 'IS_FORCE_SORTING_NON_MED_PRODS_WITHOUT_IMG_TO_BACK',
    nonMedNvp: new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), 'false'),
    MED_NVP_NAME: 'IS_FORCE_SORTING_MED_PRODS_WITHOUT_IMG_TO_BACK',
    medNvp: new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), 'false'),

    returnRouteObject: {name: 'sort-settings'} as object,
    pageUI: UIStateDto.createWithDefaults()
  }),
  mounted() {
    this.reloadContent();
  },

  computed: {
    ...mapState(useClipboardStore, ["getClipboardColorScheme"]),
    AuthContext() {
      return AuthContext
    },
  },
  methods: {
    ...mapActions(useClipboardStore, ["copyToClipboardColorScheme"]),
    async reloadContent(): Promise<void> {
      this.pageUI.setNotReady();

      try {
        const existingNonMedNvp = await NVPRestService.getInstance().findDpNvpByName(CustomerContext.getCustomerCode(), this.NON_MED_NVP_NAME, DpModuleEnum.CPC);
        const existingMedNvp = await NVPRestService.getInstance().findDpNvpByName(CustomerContext.getCustomerCode(), this.MED_NVP_NAME, DpModuleEnum.CPC);

        if (existingNonMedNvp) {
          this.nonMedNvp = new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), existingNonMedNvp.value ?? 'false');
          this.isForceNonMedicineProductsToBack = 'true' === this.nonMedNvp.value;
        } else {
          this.nonMedNvp = new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), 'false');
          this.isForceNonMedicineProductsToBack = false;
        }
        if (existingMedNvp) {
          this.medNvp = new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), existingMedNvp.value ?? 'false');
          this.isForceMedicineProductsToBack = 'true' === this.medNvp.value;
        } else {
          this.medNvp = new DpNameValuePairValueRestDtoModel(CustomerContext.getCustomerCode(), 'false');
          this.isForceMedicineProductsToBack = false;
        }

      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.pageUI.clearError();

      try {
        this.nonMedNvp.value = '' + this.isForceNonMedicineProductsToBack;
        await NVPRestService.getInstance().updateDpNvpByName(this.nonMedNvp, CustomerContext.getCustomerCode(), this.NON_MED_NVP_NAME, DpModuleEnum.CPC);
        this.medNvp.value = '' + this.isForceMedicineProductsToBack;
        await NVPRestService.getInstance().updateDpNvpByName(this.medNvp, CustomerContext.getCustomerCode(), this.MED_NVP_NAME, DpModuleEnum.CPC);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        window.scrollTo(0, 0);

        await handleSavedSuccessfully();
        await this.reloadContent();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
  }
});
