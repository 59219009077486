
import {defineComponent, PropType} from "vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import {TitleMetaDataRestDto} from "@/api/pharma-cms-content-block/models";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {ColorPickerPayload} from "@/models/payload/ColorPickerPayload";
import {TitleMetaDataRestDtoModel} from "@/models/api/pharma-cms-content-block/TitleMetaDataRestDtoModel";
import InputSelect from "@/components/UI/InputSelect.vue";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";
import {BaseColorRestDto} from "@/api/interfaces/base-color-rest-dto";

export default defineComponent({
  name: "TitleMetaInputComponent",
  components: {
    ColorPicker,
    InputSelect,
    AlertError2,
    InputNumber,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object as PropType<TitleMetaDataRestDto>,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: 'default'
    },
    class: {
      type: String,
      required: false
    },
    fieldsRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
    }
  },
  data() {
    return {
      titleMeta: TitleMetaDataRestDtoModel.createWithDefaults(),
      horizPosOptions: [] as CodeDescriptionRestDto[],
      textColorOptions: [] as CodeDescriptionRestDto[],
      fontWeightOptions: [] as CodeDescriptionRestDto[],
      previousTextColor: null as BaseColorRestDto | null,
      overwriteColorScheme: false,
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.titleMeta = this.modelValue;
    this.reloadContent();
  },
  computed: {
    inputRules(): string {
      return this.fieldsRequired ? 'required' : '';
    },
  },
  methods: {
    async reloadContent() {
      this.pageUI
        .clearError()
        .setNotReady();
      try {
        this.horizPosOptions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION);
        this.textColorOptions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.TEXT_COLOR_ENUM);
        this.fontWeightOptions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.FONT_WEIGHT_ENUM);
        if (this.titleMeta.text_color) {
          this.overwriteColorScheme = true;
          this.previousTextColor = this.titleMeta.text_color;
        } else {
          this.overwriteColorScheme = false;
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    toggleOverwriteColorScheme() {
      this.overwriteColorScheme = !this.overwriteColorScheme;
      if (this.titleMeta) {
        if (this.overwriteColorScheme) {
          if (this.previousTextColor !== null) {
            this.titleMeta.text_color = this.previousTextColor;
          } else {
            this.titleMeta.text_color = ColorRestDtoModel.createWithDefaults();
          }
        } else {
          this.previousTextColor = this.titleMeta.text_color ?? null;
          this.titleMeta.text_color = null;
        }
      }
      this.onUpdate();
    },
    onChangeTextColor(payload: ColorPickerPayload): void {
      this.titleMeta.text_color = payload.colorRestDto;
      this.onUpdate();
    },
    onUpdate() {
      this.$emit("update:modelValue", this.titleMeta);
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
});
