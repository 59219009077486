import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_selector = _resolveComponent("product-selector")!
  const _component_input_text_editor = _resolveComponent("input-text-editor")!

  return (_openBlock(), _createBlock(_component_input_text_editor, {
    modelValue: _ctx.internalModel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalModel) = $event)),
    onBlur: _ctx.onBlur,
    mode: _ctx.mode,
    height: _ctx.height,
    label: _ctx.fieldLabel,
    name: _ctx.name,
    errors: _ctx.errors
  }, {
    default: _withCtx(() => [
      _createVNode(_component_product_selector, {
        class: "col-1",
        errors: _ctx.errors,
        "single-product": false,
        onSelectProducts: _ctx.addIncludedProducts
      }, null, 8, ["errors", "onSelectProducts"])
    ]),
    _: 1
  }, 8, ["modelValue", "onBlur", "mode", "height", "label", "name", "errors"]))
}