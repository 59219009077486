
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {mapActions as mapActionsP, mapState} from "pinia";
import {Exception} from "@/api/interfaces";
import AutoSaveFeedback from "@/components/UI/AutoSaveFeedback.vue";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import CbBannerSlot from "@/components/layouts/content-pages/wysiwyg/structures/CbBannerSlot.vue";
import CbButton from "@/components/layouts/content-pages/wysiwyg/structures/CbButton.vue";
import CbContactForm from "@/components/layouts/content-pages/wysiwyg/structures/CbContactForm.vue";
import CbDocument from "@/components/layouts/content-pages/wysiwyg/structures/CbDocument.vue";
import CbHtml from "@/components/layouts/content-pages/wysiwyg/structures/CbHtml.vue";
import CbImage from "@/components/layouts/content-pages/wysiwyg/structures/CbImage.vue";
import CbPageReferences from "@/components/layouts/content-pages/wysiwyg/structures/CbPageReferences.vue";
import CbPharmacyInfo from "@/components/layouts/content-pages/wysiwyg/structures/CbPharmacyInfo.vue";
import CbProductFilter from "@/components/layouts/content-pages/wysiwyg/structures/CbProductFilter.vue";
import CbRowSeparator from "@/components/layouts/content-pages/wysiwyg/structures/CbRowSeparator.vue";
import CbSymbol from "@/components/layouts/content-pages/wysiwyg/structures/CbSymbol.vue";
import CbTeamInfo from "@/components/layouts/content-pages/wysiwyg/structures/CbTeamInfo.vue";
import CbText from "@/components/layouts/content-pages/wysiwyg/structures/CbText.vue";
import CbTextWithImage from "@/components/layouts/content-pages/wysiwyg/structures/CbTextWithImage.vue";
import CbTextWithSymbol from "@/components/layouts/content-pages/wysiwyg/structures/CbTextWithSymbol.vue";
import CbTextWithTitle from "@/components/layouts/content-pages/wysiwyg/structures/CbTextWithTitle.vue";
import {TinyEmitter} from "tiny-emitter";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import Subtitle from "@/components/UI/Subtitle.vue";
import ButtonCbLibraries from "@/components/layouts/content-pages/wysiwyg/libraries/ButtonCbLibraries.vue";
import ButtonContentPageSaveAbstract from "@/components/layouts/content-pages/ButtonContentPageSaveAbstract.vue";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import {WysiwygContentBlockInRowRestDtoModel} from "@/models/WysiwygContentBlockInRowRestDtoModel";
import {AuthContext} from "@/context/AuthContext";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import BaseOffcanvasButtonClose from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonClose.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import {useClipboardStore} from "@/stores/ClipboardStore";
import {ContentBlockInRowRestDtoModel} from "@/models/ContentBlockInRowRestDtoModel";
import {useToast} from "vue-toastification";
import {ContentBlockStructureEnum} from "@/models/enum/ContentBlockStructureEnum";
import InputNumber from "@/components/UI/InputNumber.vue";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import {BackgroundImageRestDtoModel} from "@/models/api/pharma-cms-content-page/BackgroundImageRestDtoModel";
import InputFile from "@/components/UI/InputFile.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";
import {generateDomId} from "@/helpers/functions/string";

export default defineComponent({
  name: "ContentPageColumnDetail",
  components: {
    InputCheckboxBoolean,
    InputFile,
    ColorPicker,
    InputNumber,
    AlertError,
    InputSelect,
    BaseOffcanvasButtonClose,
    BaseSpinner,
    ButtonContentPageSaveAbstract,
    ButtonCbLibraries,
    Subtitle,
    AutoSaveFeedback,
    CbBannerSlot,
    CbButton,
    CbContactForm,
    CbDocument,
    CbHtml,
    CbImage,
    CbPageReferences,
    CbPharmacyInfo,
    CbProductFilter,
    CbRowSeparator,
    CbSymbol,
    CbTeamInfo,
    CbText,
    CbTextWithImage,
    CbTextWithSymbol,
    CbTextWithTitle
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    rowId: {
      type: String,
      required: true,
    },
    isHomepage: {
      type: Boolean,
      required: false,
      default: false
    },
    isNamedPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isReady: false as boolean,
      exceptions: [] as Exception[],
      bus: new TinyEmitter(),
      toaster: useToast(),

      alertDomId : generateDomId(),

      autoSaveFeedbackStatus: AutoSaveFeedbackStatusEnum.NONE,
      wysiwygCbInRow: null as WysiwygContentBlockInRowRestDtoModel | null,
      cbInRow: null as ContentBlockInRowRestDtoModel | null,
      bgColorDto: null as ColorRestDtoModel | null,
      bgImgDto: null as BackgroundImageRestDtoModel | null,
      isCustomColorPanelVisible: false,
      isBgImgPanelVisible: false as boolean,
      docType: 'PAGE_IMG' as string,
      imagePreviewUrl: null as string | null,

      currentComponent: null as string | null,
      copyCpAbstractPayload: CopyCpAbstractPayload.createWithDefaults(),

      contentBlockTypesRestrictedFromCopyMetadata: [
        ContentBlockTypesEnum.BANNER,
        ContentBlockTypesEnum.BANNER_SLOT,
        ContentBlockTypesEnum.CONTACT_FORM,
        ContentBlockTypesEnum.HTML,
        ContentBlockTypesEnum.TEXT,
        ContentBlockTypesEnum.TEXT_CARD,
      ] as Array<ContentBlockTypesEnum>,
    };
  },
  watch: {
    id: {
      handler() {
        this.reloadContent();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('cms_content_page_wysiwyg', ["getWysiwygContentBlockInRowById"]),
    ...mapGetters('cms_enum', ["getEnumByClassname", "getCodeDescriptionByCodeFromEnumByClassname"]),
    ...mapState(useClipboardStore, ["getClipboardContentBlock", "getClipboardContentBlockInRow"]),
    existingDocumentKey(): string | null {
      return this.bgImgDto?.image_key ?? null;
    },
    AuthContext() {
      return AuthContext
    },
    ContentBlockTypesEnum() {
      return ContentBlockTypesEnum
    },
    enumColumnWidthCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.COLUMN_SIZE);
    },
    enumBackgroundColorCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BACKGROUND_COLOR);
    },
    enumPaddingCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.PADDING);
    },
    enumColumnHorizontalAlignmentCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.COLUMN_HORIZONTAL_ALIGNMENT);
    },
    contentBlockStructureDescription(): string {
      return this.getCodeDescriptionByCodeFromEnumByClassname(EnumClassNameCmsEnum.CONTENT_BLOCK_STRUCTURE, this.cbInRow?.structure_code)?.description ?? '';
    },
    enumBgImgHorizontalPositionCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_HOR_POSITION);
    },
    enumBgImgVerticalPositionCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_VER_POSITION);
    },
    enumBgImgRepeatCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_REPEAT);
    },
    enumBgImgSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_SIZE);
    },
    enumBgImgAttachmentCode(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCmsEnum.BG_IMG_ATTACHMENT);
    },
  },
  methods: {
    ...mapActions('cms_content_page_wysiwyg', ["saveContentPageColumn"]),
    ...mapActions('cms_enum', ["findManyEnumByClassname"]),
    ...mapActionsP(useClipboardStore, ["copyToClipboardContentBlockInRow"]),
    toggleBackgroundColorType(): void {
      this.isCustomColorPanelVisible = !this.isCustomColorPanelVisible;
    },
    async setImageKey(key: string | null): Promise<void> {
      if (this.bgImgDto) {
        if (key) {
          this.bgImgDto.image_key = key;
        }
      }

      if (key !== null) {
        this.imagePreviewUrl = await CmsDocumentRestService.getInstance().findPreviewUrl(key);
      } else {
        this.imagePreviewUrl = null;
      }

      // this.onChange();
    },
    findColumnInStore() {
      this.wysiwygCbInRow = this.getWysiwygContentBlockInRowById(this.id);
      this.cbInRow = this.wysiwygCbInRow?.cb_in_row ?? null;
    },
    onCopyCpAbstractChanged(payload: CopyCpAbstractPayload) {
      this.copyCpAbstractPayload = payload;
    },
    onCopyCbMetadata(): void {
      this.bus.emit('onCopyCbMetadata');
    },
    onCopyCbInRowMetadata(): void {
      if (this.cbInRow) {
        this.copyToClipboardContentBlockInRow(this.cbInRow);

        this.toaster.info(this.$t('cms.successfullyCopiedMetadata'));
      }
    },
    onPasteCbMetadata(): void {
      this.bus.emit('onPasteCbMetadata');
    },
    onPasteCbInRowMetadata(): void {
      if (this.cbInRow !== null && this.cbInRow !== undefined) {
        const id = this.cbInRow.cb_id;
        this.cbInRow = ContentBlockInRowRestDtoModel.cloneFrom(this.getClipboardContentBlockInRow as ContentBlockInRowRestDtoModel);
        this.cbInRow.cb_id = id;
        this.reloadNestedElements();
      }

      this.setHasUnsavedChanges();
      this.toaster.info(this.$t('cms.successfullyPastedMetadata'));
    },
    async reloadContent(): Promise<void> {
      this.exceptions = [];
      this.isReady = false;

      await this.findManyEnumByClassname([
        EnumClassNameCmsEnum.COLUMN_SIZE,
        EnumClassNameCmsEnum.BACKGROUND_COLOR,
        EnumClassNameCmsEnum.PADDING,
        EnumClassNameCmsEnum.COLUMN_HORIZONTAL_ALIGNMENT,
        EnumClassNameCmsEnum.CONTENT_BLOCK_STRUCTURE,
        EnumClassNameCmsEnum.BG_IMG_HOR_POSITION,
        EnumClassNameCmsEnum.BG_IMG_VER_POSITION,
        EnumClassNameCmsEnum.BG_IMG_SIZE,
        EnumClassNameCmsEnum.BG_IMG_REPEAT,
        EnumClassNameCmsEnum.BG_IMG_ATTACHMENT,
      ]);

      this.currentComponent = null;
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.NONE;
      this.findColumnInStore();
      this.bus = new TinyEmitter();

      switch (this.cbInRow?.structure_code) {
        case ContentBlockStructureEnum.ARTICLE_HEADING:
          this.currentComponent = 'cb-text-with-image';
          break;
        case ContentBlockStructureEnum.CALL_TO_ACTION:
          this.currentComponent = 'cb-button';
          break;
        case ContentBlockStructureEnum.PAGE_TITLE:
          this.currentComponent = 'cb-text-with-title';
          break;
        case ContentBlockStructureEnum.PRODUCT_FILTER_AS_GRID:
        case ContentBlockStructureEnum.PRODUCT_FILTER_AS_SLIDER:
          this.currentComponent = 'cb-product-filter';
          break;
        case ContentBlockStructureEnum.TEXT_CARD:
          this.currentComponent = 'cb-text';
          break;
        case ContentBlockStructureEnum.BANNER_SLOT:
        case ContentBlockStructureEnum.BUTTON:
        case ContentBlockStructureEnum.CONTACT_FORM:
        case ContentBlockStructureEnum.DOCUMENT:
        case ContentBlockStructureEnum.HTML:
        case ContentBlockStructureEnum.IMAGE:
        case ContentBlockStructureEnum.PAGE_REFERENCES:
        case ContentBlockStructureEnum.PHARMACY_INFO:
        case ContentBlockStructureEnum.ROW_SEPARATOR:
        case ContentBlockStructureEnum.SYMBOL:
        case ContentBlockStructureEnum.TEAM_INFO:
        case ContentBlockStructureEnum.TEXT:
        case ContentBlockStructureEnum.TEXT_WITH_IMAGE:
        case ContentBlockStructureEnum.TEXT_WITH_SYMBOL:
        case ContentBlockStructureEnum.TEXT_WITH_TITLE:
          this.currentComponent = 'cb-' + this.cbInRow.structure_code.toLowerCase().replaceAll('_', '-');
          break;
      }
      this.reloadNestedElements();

      this.isReady = true;
    },
    reloadNestedElements() {
      if (this.cbInRow !== null && this.cbInRow.background_color !== null && this.cbInRow.background_color !== undefined) {
        this.bgColorDto = this.cbInRow.background_color;
        this.isCustomColorPanelVisible = true;
      } else {
        this.bgColorDto = ColorRestDtoModel.createWithDefaults();
        this.isCustomColorPanelVisible = false;
      }
      if (this.cbInRow !== null && this.cbInRow.background_image !== null && this.cbInRow.background_image !== undefined) {
        this.bgImgDto = this.cbInRow.background_image;
        this.imagePreviewUrl = this.bgImgDto.image_url;
        this.isBgImgPanelVisible = true;
      } else {
        this.bgImgDto = BackgroundImageRestDtoModel.createWithDefaults();
        this.imagePreviewUrl = null;
        this.isBgImgPanelVisible = false;
      }
    },
    save() {
      if (this.cbInRow !== null && this.bgColorDto !== null) {
        if (this.isCustomColorPanelVisible) {
          // A specific color is picked
          this.cbInRow.background_color = this.bgColorDto;
        } else {
          this.cbInRow.background_color = null as unknown as ColorRestDtoModel;
          this.bgColorDto = ColorRestDtoModel.createWithDefaults();
        }
      }
      if (this.cbInRow !== null && this.bgImgDto !== null) {
        if (this.isBgImgPanelVisible && this.bgImgDto.image_key !== '') {
          this.cbInRow.background_image = this.bgImgDto;
        } else {
          this.cbInRow.background_image = null as unknown as BackgroundImageRestDtoModel;
          this.bgImgDto = BackgroundImageRestDtoModel.createWithDefaults();
        }
      }

      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.BUSY;
      this.bus.emit('submit');
    },
    async saveMetaData() {
      try {
        await this.saveContentPageColumn({
          cb_in_row_id: this.id,
          row_id: this.rowId,
          cb_in_row: this.cbInRow,
        });
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.SUCCESS;
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    setHasUnsavedChanges() {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
    },
    afterSaveContent(cbId: string | null) {
      if (this.cbInRow && cbId !== null) {
        this.cbInRow.cb_id = cbId;
      }

      this.saveMetaData();
    },
    setExceptions(exceptions: Exception[]) {
      this.exceptions = exceptions;

      if (exceptions.length) {
        this.exceptions = exceptions;
        this.$nextTick(() => {
          document.getElementById(this.alertDomId)?.scrollIntoView({behavior: 'smooth'});
        });

        if (exceptions.length) {
          this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.FAILED;
        } else {
          this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.NONE;
        }
      }
    }
  },
});
