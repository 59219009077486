import {RestService} from "@/services/rest/RestService";
import {CatalogProductAPIApi, ProductPriceAPIApi} from "@/api/pharma-cpc-product-mgmt";
import {
    CatalogProductDetailRestDto, CustomerProductPriceForProductRestDto, CustomerProductPriceRestDto,
    PagedCatalogProductOverviewResultsRestDto,
    ProductPriceInfoRestDto
} from "@/api/pharma-cpc-product-mgmt/models";
import {CatalogProductsCriteriaModel} from "@/models/criteria/CatalogProductsCriteriaModel";
import {CatalogProductsCriteriaRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductsCriteriaRestDtoModel";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {
    ProductPropertyAPIApi,
} from "@/api/pharma-cpc-product-mgmt";
import {
    CustomerProductPropertiesResultsRestDto,
    CustomerProductPropertyRestDto, ProductCustomNameRestDto, ProductCustomNamesOverviewRestDto,
} from "@/api/pharma-cpc-product-mgmt/models";
import {PhotoRestService} from "@/services/rest/photo/PhotoRestService";

export class CatalogProductsRestService extends RestService {

    private apiService: CatalogProductAPIApi;
    private propertiesApiService: ProductPropertyAPIApi;
    private priceApiService: ProductPriceAPIApi;

    protected constructor() {
        super();

        this.apiService = new CatalogProductAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
        this.propertiesApiService = new ProductPropertyAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
        this.priceApiService = new ProductPriceAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cpcUrl);
    }

    public async findProductCustomNames(productCode: string): Promise<ProductCustomNamesOverviewRestDto> {
        const response = await this.propertiesApiService.findProductCustomNames(productCode);

        return response.data;
    }

    public async syncProductCustomNames(productCode: string, dto: ProductCustomNameRestDto): Promise<void> {
        await this.propertiesApiService.syncProductCustomNames(dto, productCode);
    }

    public async syncProductCustomerPrice(productCode: string, body: CustomerProductPriceRestDto): Promise<void> {
        await this.priceApiService.syncProductCustomerPrice(body, productCode);
    }

    public async deleteProductCustomerPrice(productCode: string): Promise<void> {
        await this.priceApiService.deleteProductCustomerPrice(productCode);
    }

    public async findProductCustomerPrice(productCode: string): Promise<CustomerProductPriceForProductRestDto> {
        const response = await this.priceApiService.findProductCustomerPrice(productCode, null);

        return response.data;
    }

    public async findProductPriceInfo(productCode: string): Promise<ProductPriceInfoRestDto> {
        const response = await this.priceApiService.findProductPriceInfo(productCode, null);

        return response.data;
    }

    public async findCustomerProductProperties(productCode: string, languageCode: string, typecode: string |null): Promise<CustomerProductPropertiesResultsRestDto> {
        const response = await this.propertiesApiService.findCustomerProductProperties(productCode, null, languageCode, typecode);

        return response.data;
    }

    public async deleteProductProperty(productCode: string, typeCode: string, lanuageCode: string): Promise<void> {
        await this.propertiesApiService.deleteProductProperty(productCode, typeCode, lanuageCode);
    }

    public async syncProductProperty(productCode: string, dto: CustomerProductPropertyRestDto): Promise<void> {
        await this.propertiesApiService.syncProductProperty(dto, productCode);
    }

    public async findCatalogProducts(
        criteria: CatalogProductsCriteriaModel,
        pagingModel: PimProductsPagingModel,
        language: string
    ): Promise<PagedCatalogProductOverviewResultsRestDto> {
        const criteriaDto = new CatalogProductsCriteriaRestDtoModel(
            criteria,
            pagingModel
            , null
        );
        const response = await this.apiService.findCatalogProductsByCatalogProductsCriteriaRestDto(criteriaDto);

        await PhotoRestService.getInstance().fillProductImageUrls(response.data.results, language);

        return response.data;
    }

    public async getCatalogProductDetail(productCode: string): Promise<CatalogProductDetailRestDto> {
        const response = await this.apiService.getCatalogProductDetail(productCode);

        return response.data;
    }

}
