import {RestService} from "@/services/rest/RestService";
import {CacheService} from "@/services/cache/CacheService";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";
import {ProductBrandAPIApi, ProductCategoryAPIApi, ProductTrademarkAPIApi} from "@/api/pharma-cpc-product";
import {CodeDescriptionRestDto, ProductCategoryOverviewRestDto, ProductCategoryRestDto} from "@/api/pharma-cpc-product/models";
import {CustomerContext} from "@/context/CustomerContext";
import {ImageAPIApi} from "@/api/pharma-photo-product-image";
import {ProductImageResponseRestDto, ProductImagesRequestRestDto} from "@/api/pharma-photo-product-image/models";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import {trimLeadingZeroes} from "@/helpers/functions/number";

export class PhotoRestService extends RestService {

    private imageApiService: ImageAPIApi;

    private static readonly NOT_FOUND_URL: string = 'https://cdn.dp2.digital-pharma.eu/assets/img/blancoverpakking.jpg';

    protected constructor() {
        super();

        this.imageApiService = new ImageAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().photoUrl);
    }

    public async fillProductImageUrls(
        products: CatalogProductOverviewRestDtoModel[],
        language: string
    ): Promise<void> {
        const customerCode = CustomerContext.getCustomerCode();
        const cacheKeyPrefix: string = CacheKeyEnum.PRODUCT_IMAGE_URL
            + '.' + (language ?? '--')
            + '.';

        const missingUrlCnkCodes: string[] = [];

        for (const product of products) {
            if (product.product_info.cnk_code) {
                const trimmedCnKCode = trimLeadingZeroes(product.product_info.cnk_code);
                const url: string = CacheService.getInstance().get(
                    cacheKeyPrefix + trimmedCnKCode,
                    customerCode
                );
                if (url) {
                    product.main_image_url = url;
                } else if (product.product_info.cnk_code) {
                    missingUrlCnkCodes.push(trimmedCnKCode);
                }
            }
        }

        if (!missingUrlCnkCodes.length) {
            return;
        }

        const productImagesRequestDto: ProductImagesRequestRestDto = {
            language: language,
            cnk_codes: missingUrlCnkCodes
        };

        const response = await this.imageApiService.findManyMainProductImage(productImagesRequestDto);
        const results: Array<ProductImageResponseRestDto> = response.data;
        if (results) {
            for (const imageResponseDto of results) {
                const url: string = imageResponseDto.url ?? PhotoRestService.NOT_FOUND_URL;
                if (imageResponseDto.cnk_code) {
                    const trimmedCnKCode = trimLeadingZeroes(imageResponseDto.cnk_code);
                    CacheService.getInstance().set(
                        cacheKeyPrefix + trimmedCnKCode,
                        url,
                        customerCode,
                        58 * 60_000 /* URLs are valid for 60 minutes, so cache them for 58 minutes */
                    );
                    products
                        .filter(product =>
                            (product.product_info.cnk_code
                                && trimLeadingZeroes(product.product_info.cnk_code) === trimmedCnKCode))
                        .map(product => product.main_image_url = url);
                }
            }
        }
    }
}
