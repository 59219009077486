import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-baee1564"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row row-product-order-img-based" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row row-product-order-img-based" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  type: "submit",
  class: "btn btn-primary mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!
  const _component_CatalogBuildStatus = _resolveComponent("CatalogBuildStatus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_BaseTitle, {
          title: _ctx.$t('productOrderImgageBased'),
          mb: 5,
          "show-refresh-button": "",
          onRefresh: this.reloadContent,
          "help-text": _ctx.$t('descriptionProductOrderImageBased'),
          "back-route": this.returnRouteObject
        }, null, 8, ["title", "onRefresh", "help-text", "back-route"]),
        _createVNode(_component_AlertError2, {
          error: this.pageUI.getError(),
          class: "mb-5"
        }, null, 8, ["error"]),
        _createVNode(_component_BaseSpinner, {
          spin: this.pageUI.isNotReady()
        }, null, 8, ["spin"]),
        (this.pageUI.isReady())
          ? (_openBlock(), _createBlock(_component_dp_form, {
              key: 0,
              onSubmit: this.submitData,
              as: "form",
              novalidate: ""
            }, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_InputCheckboxBoolean, {
                      modelValue: _ctx.isForceNonMedicineProductsToBack,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isForceNonMedicineProductsToBack) = $event)),
                      label: _ctx.$t('isForceNonMedicineProductsToBackLabel'),
                      name: "nonMedicine",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_InputCheckboxBoolean, {
                      modelValue: _ctx.isForceMedicineProductsToBack,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isForceMedicineProductsToBack) = $event)),
                      label: _ctx.$t('isForceMedicineProductsToBackLabel'),
                      name: "medicine",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors"])
                  ])
                ]),
                _createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.$t('save')), 1)
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_CatalogBuildStatus)
  ], 64))
}