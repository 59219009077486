/* tslint:disable */
/* eslint-disable */
/**
 * /pharma-photo/product-image
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ProductImageResponseRestDto } from '../models';
import { ProductImagesRequestRestDto } from '../models';
/**
 * ImageAPIApi - axios parameter creator
 * @export
 */
export const ImageAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint verantwoordelijk voor het hosten van Digital-Pharma product foto's. Via dit endpoint wordt specifiek de 'hoofdfoto' gehost, voor specifiek productfoto's, is er een aparte resource voorzien binnen deze API.
         * @summary REST.API.PP.IMG.01 Toon hoofd foto voor product.
         * @param {number} cnk_code De cnk code waarmee een product uniek geïdentificeerd wordt. Het is een unieke code die bestaat uit 7 cijfers.
         * @param {string} dp_customer_code De unieke identificatie van de Digital-Pharma klant die deze request initieerde.
         * @param {string} [accept] Het gewenste response Content-Type. Momenteel wordt énkel image/jpeg ondersteund.
         * @param {string} [dp_auth_token] Het JWT waarmee de request geauthorizeerd wordt.
         * @param {string} [language] De gewenste taal waarvoor de foto opgevraagd wordt.
         * @param {string} [fallback_language] De taal waarop teruggevallen wordt indien er geen foto in de gewenste taal gevonden wordt.
         * @param {string} [language_mode] Geeft aan op welke manier de API om gaat met de opgegeven &#x27;language&#x27; parameter. In geval van &#x27;STRICT&#x27;, zal de API status code 404 teruggeven wanneer er géén foto gevonden wordt voor de opgegeven taal. In geval van &#x27;FALLBACK&#x27;, zal de API terugvallen op de default taal &#x27;nl&#x27;.
         * @param {string} [not_found_redirect_url] Wanneer de redirect URL opgegeven wordt, wordt er nooit een status code 404 teruggegeven, maar een status code 302 met de opgegeven redirect URL als location in de response. De opgegeven URL moet HTML encoded zijn.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMainProductImage: async (cnk_code: number, dp_customer_code: string, accept?: string|null, dp_auth_token?: string|null, language?: string|null, fallback_language?: string|null, language_mode?: string|null, not_found_redirect_url?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cnk_code' is not null or undefined
            if (cnk_code === null || cnk_code === undefined) {
                throw new RequiredError('cnk_code','Required parameter cnk_code was null or undefined when calling findMainProductImage.');
            }
            // verify required parameter 'dp_customer_code' is not null or undefined
            if (dp_customer_code === null || dp_customer_code === undefined) {
                throw new RequiredError('dp_customer_code','Required parameter dp_customer_code was null or undefined when calling findMainProductImage.');
            }
            const localVarPath = `/img/v1/{cnk_code}`
                .replace(`{${"cnk_code"}}`, encodeURIComponent(String(cnk_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!dp_auth_token || typeof(dp_auth_token) === "boolean") {
                localVarQueryParameter['dp-auth-token'] = dp_auth_token;
            }

            if (!!dp_customer_code || typeof(dp_customer_code) === "boolean") {
                localVarQueryParameter['dp_customer_code'] = dp_customer_code;
            }

            if (!!language || typeof(language) === "boolean") {
                localVarQueryParameter['language'] = language;
            }

            if (!!fallback_language || typeof(fallback_language) === "boolean") {
                localVarQueryParameter['fallback_language'] = fallback_language;
            }

            if (!!language_mode || typeof(language_mode) === "boolean") {
                localVarQueryParameter['language_mode'] = language_mode;
            }

            if (!!not_found_redirect_url || typeof(not_found_redirect_url) === "boolean") {
                localVarQueryParameter['not_found_redirect_url'] = not_found_redirect_url;
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint verantwoordelijk voor het hosten van Digital-Pharma product foto's. Via dit endpoint wordt specifiek de 'hoofdfoto' gehost, voor specifiek productfoto's, is er een aparte resource voorzien binnen deze API.
         * @summary REST.API.PP.IMG.02 Hoofd foto voor meerdere producten.
         * @param {ProductImagesRequestRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findManyMainProductImage: async (body: ProductImagesRequestRestDto, x_dp_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling findManyMainProductImage.');
            }
            const localVarPath = `/img/v1/many`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageAPIApi - functional programming interface
 * @export
 */
export const ImageAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint verantwoordelijk voor het hosten van Digital-Pharma product foto's. Via dit endpoint wordt specifiek de 'hoofdfoto' gehost, voor specifiek productfoto's, is er een aparte resource voorzien binnen deze API.
         * @summary REST.API.PP.IMG.01 Toon hoofd foto voor product.
         * @param {number} cnk_code De cnk code waarmee een product uniek geïdentificeerd wordt. Het is een unieke code die bestaat uit 7 cijfers.
         * @param {string} dp_customer_code De unieke identificatie van de Digital-Pharma klant die deze request initieerde.
         * @param {string} [accept] Het gewenste response Content-Type. Momenteel wordt énkel image/jpeg ondersteund.
         * @param {string} [dp_auth_token] Het JWT waarmee de request geauthorizeerd wordt.
         * @param {string} [language] De gewenste taal waarvoor de foto opgevraagd wordt.
         * @param {string} [fallback_language] De taal waarop teruggevallen wordt indien er geen foto in de gewenste taal gevonden wordt.
         * @param {string} [language_mode] Geeft aan op welke manier de API om gaat met de opgegeven &#x27;language&#x27; parameter. In geval van &#x27;STRICT&#x27;, zal de API status code 404 teruggeven wanneer er géén foto gevonden wordt voor de opgegeven taal. In geval van &#x27;FALLBACK&#x27;, zal de API terugvallen op de default taal &#x27;nl&#x27;.
         * @param {string} [not_found_redirect_url] Wanneer de redirect URL opgegeven wordt, wordt er nooit een status code 404 teruggegeven, maar een status code 302 met de opgegeven redirect URL als location in de response. De opgegeven URL moet HTML encoded zijn.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMainProductImage(_axios: AxiosInstance, cnk_code: number, dp_customer_code: string, accept?: string|null, dp_auth_token?: string|null, language?: string|null, fallback_language?: string|null, language_mode?: string|null, not_found_redirect_url?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ImageAPIApiAxiosParamCreator(configuration).findMainProductImage(cnk_code, dp_customer_code, accept, dp_auth_token, language, fallback_language, language_mode, not_found_redirect_url, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint verantwoordelijk voor het hosten van Digital-Pharma product foto's. Via dit endpoint wordt specifiek de 'hoofdfoto' gehost, voor specifiek productfoto's, is er een aparte resource voorzien binnen deze API.
         * @summary REST.API.PP.IMG.02 Hoofd foto voor meerdere producten.
         * @param {ProductImagesRequestRestDto} body 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findManyMainProductImage(_axios: AxiosInstance, body: ProductImagesRequestRestDto, x_dp_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductImageResponseRestDto>>> {
            const localVarAxiosArgs = await ImageAPIApiAxiosParamCreator(configuration).findManyMainProductImage(body, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ImageAPIApi - object-oriented interface
 * @export
 * @class ImageAPIApi
 * @extends {BaseAPI}
 */
export class ImageAPIApi extends BaseAPI {
    /**
     * API endpoint verantwoordelijk voor het hosten van Digital-Pharma product foto's. Via dit endpoint wordt specifiek de 'hoofdfoto' gehost, voor specifiek productfoto's, is er een aparte resource voorzien binnen deze API.
     * @summary REST.API.PP.IMG.01 Toon hoofd foto voor product.
     * @param {number} cnk_code De cnk code waarmee een product uniek geïdentificeerd wordt. Het is een unieke code die bestaat uit 7 cijfers.
     * @param {string} dp_customer_code De unieke identificatie van de Digital-Pharma klant die deze request initieerde.
     * @param {string} [accept] Het gewenste response Content-Type. Momenteel wordt énkel image/jpeg ondersteund.
     * @param {string} [dp_auth_token] Het JWT waarmee de request geauthorizeerd wordt.
     * @param {string} [language] De gewenste taal waarvoor de foto opgevraagd wordt.
     * @param {string} [fallback_language] De taal waarop teruggevallen wordt indien er geen foto in de gewenste taal gevonden wordt.
     * @param {string} [language_mode] Geeft aan op welke manier de API om gaat met de opgegeven &#x27;language&#x27; parameter. In geval van &#x27;STRICT&#x27;, zal de API status code 404 teruggeven wanneer er géén foto gevonden wordt voor de opgegeven taal. In geval van &#x27;FALLBACK&#x27;, zal de API terugvallen op de default taal &#x27;nl&#x27;.
     * @param {string} [not_found_redirect_url] Wanneer de redirect URL opgegeven wordt, wordt er nooit een status code 404 teruggegeven, maar een status code 302 met de opgegeven redirect URL als location in de response. De opgegeven URL moet HTML encoded zijn.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAPIApi
     */
     public findMainProductImage(cnk_code: number, dp_customer_code: string, accept?: string|null, dp_auth_token?: string|null, language?: string|null, fallback_language?: string|null, language_mode?: string|null, not_found_redirect_url?: string|null, options?: any) {
        return ImageAPIApiFp(this.configuration).findMainProductImage(this.axios, cnk_code, dp_customer_code, accept, dp_auth_token, language, fallback_language, language_mode, not_found_redirect_url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint verantwoordelijk voor het hosten van Digital-Pharma product foto's. Via dit endpoint wordt specifiek de 'hoofdfoto' gehost, voor specifiek productfoto's, is er een aparte resource voorzien binnen deze API.
     * @summary REST.API.PP.IMG.02 Hoofd foto voor meerdere producten.
     * @param {ProductImagesRequestRestDto} body 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAPIApi
     */
     public findManyMainProductImage(body: ProductImagesRequestRestDto, x_dp_customer_code?: string|null, options?: any) {
        return ImageAPIApiFp(this.configuration).findManyMainProductImage(this.axios, body, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
