import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a2ea47b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "title-size-picker btn-group btn-group-sm w-auto mb-5",
  role: "group",
  "aria-label": "Title size"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "row row-body-scheme" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "mb-4" }
const _hoisted_9 = { class: "row row-body-scheme" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "mb-4" }
const _hoisted_14 = { class: "row row-body-scheme" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_17 = { key: 3 }
const _hoisted_18 = { class: "mb-4" }
const _hoisted_19 = { class: "row row-body-scheme" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_22 = { key: 4 }
const _hoisted_23 = { class: "mb-4" }
const _hoisted_24 = { class: "row row-body-scheme" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_27 = { key: 5 }
const _hoisted_28 = { class: "mb-4" }
const _hoisted_29 = { class: "row row-body-scheme" }
const _hoisted_30 = { class: "col" }
const _hoisted_31 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_TitleMetaInputComponent = _resolveComponent("TitleMetaInputComponent")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('titleScheme'),
        mb: 5,
        "show-refresh-button": "",
        onRefresh: this.reloadContent,
        "back-route": this.returnRouteObject
      }, null, 8, ["title", "onRefresh", "back-route"]),
      _createVNode(_component_AlertError2, {
        error: this.pageUI.getError(),
        class: "mb-5"
      }, null, 8, ["error"]),
      _createVNode(_component_BaseSpinner, {
        spin: this.pageUI.isNotReady()
      }, null, 8, ["spin"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setEnabledTab('H1'))),
          class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.isEnabledTab('H1')}]),
          type: "button"
        }, " H1 ", 2),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setEnabledTab('H2'))),
          class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.isEnabledTab('H2')}]),
          type: "button"
        }, " H2 ", 2),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setEnabledTab('H3'))),
          class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.isEnabledTab('H3')}]),
          type: "button"
        }, " H3 ", 2),
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setEnabledTab('H4'))),
          class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.isEnabledTab('H4')}]),
          type: "button"
        }, " H4 ", 2),
        _createElementVNode("button", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setEnabledTab('H5'))),
          class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.isEnabledTab('H5')}]),
          type: "button"
        }, " H5 ", 2),
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setEnabledTab('H6'))),
          class: _normalizeClass(['btn btn-outline-primary', {'active': _ctx.isEnabledTab('H6')}]),
          type: "button"
        }, " H6 ", 2)
      ]),
      (_ctx.isEnabledTab('H1'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (this.pageUI.isReady())
                ? (_openBlock(), _createBlock(_component_dp_form, {
                    key: 0,
                    onSubmit: _cache[7] || (_cache[7] = ($event: any) => (this.submitData('H1'))),
                    as: "form",
                    novalidate: ""
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_TitleMetaInputComponent, {
                            modelValue: this.titleMetaH1,
                            "fields-required": true,
                            errors: errors
                          }, null, 8, ["modelValue", "errors"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('save')), 1),
                      _createElementVNode("button", {
                        class: "btn btn-secondary ms-3",
                        onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.resetToDefaults('H1')), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEnabledTab('H2'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (this.pageUI.isReady())
                ? (_openBlock(), _createBlock(_component_dp_form, {
                    key: 0,
                    onSubmit: _cache[9] || (_cache[9] = ($event: any) => (this.submitData('H2'))),
                    as: "form",
                    novalidate: ""
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_TitleMetaInputComponent, {
                            modelValue: this.titleMetaH2,
                            "fields-required": true,
                            errors: errors
                          }, null, 8, ["modelValue", "errors"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('save')), 1),
                      _createElementVNode("button", {
                        class: "btn btn-secondary ms-3",
                        onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.resetToDefaults('H2')), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEnabledTab('H3'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              (this.pageUI.isReady())
                ? (_openBlock(), _createBlock(_component_dp_form, {
                    key: 0,
                    onSubmit: _cache[11] || (_cache[11] = ($event: any) => (this.submitData('H3'))),
                    as: "form",
                    novalidate: ""
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_TitleMetaInputComponent, {
                            modelValue: this.titleMetaH3,
                            "fields-required": true,
                            errors: errors
                          }, null, 8, ["modelValue", "errors"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_16, _toDisplayString(_ctx.$t('save')), 1),
                      _createElementVNode("button", {
                        class: "btn btn-secondary ms-3",
                        onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.resetToDefaults('H3')), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEnabledTab('H4'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              (this.pageUI.isReady())
                ? (_openBlock(), _createBlock(_component_dp_form, {
                    key: 0,
                    onSubmit: _cache[13] || (_cache[13] = ($event: any) => (this.submitData('H4'))),
                    as: "form",
                    novalidate: ""
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createVNode(_component_TitleMetaInputComponent, {
                            modelValue: this.titleMetaH4,
                            "fields-required": true,
                            errors: errors
                          }, null, 8, ["modelValue", "errors"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_21, _toDisplayString(_ctx.$t('save')), 1),
                      _createElementVNode("button", {
                        class: "btn btn-secondary ms-3",
                        onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.resetToDefaults('H4')), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEnabledTab('H5'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              (this.pageUI.isReady())
                ? (_openBlock(), _createBlock(_component_dp_form, {
                    key: 0,
                    onSubmit: _cache[15] || (_cache[15] = ($event: any) => (this.submitData('H5'))),
                    as: "form",
                    novalidate: ""
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_TitleMetaInputComponent, {
                            modelValue: this.titleMetaH5,
                            "fields-required": true,
                            errors: errors
                          }, null, 8, ["modelValue", "errors"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_26, _toDisplayString(_ctx.$t('save')), 1),
                      _createElementVNode("button", {
                        class: "btn btn-secondary ms-3",
                        onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.resetToDefaults('H5')), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isEnabledTab('H6'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              (this.pageUI.isReady())
                ? (_openBlock(), _createBlock(_component_dp_form, {
                    key: 0,
                    onSubmit: _cache[17] || (_cache[17] = ($event: any) => (this.submitData('H6'))),
                    as: "form",
                    novalidate: ""
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _createVNode(_component_TitleMetaInputComponent, {
                            modelValue: this.titleMetaH6,
                            "fields-required": true,
                            errors: errors
                          }, null, 8, ["modelValue", "errors"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_31, _toDisplayString(_ctx.$t('save')), 1),
                      _createElementVNode("button", {
                        class: "btn btn-secondary ms-3",
                        onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (_ctx.resetToDefaults('H6')), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('restoreDefaultValues')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}