
import {defineComponent} from 'vue'
import {convertProductCodesListToArray, productCodesArrayIsValid} from "@/helpers/functions/products";
import {DpException} from "@/exception/DpException";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";
import ProductSelector from "@/components/UI/product/ProductSelector.vue";
import {CatalogProductOverviewRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {trimLeadingZeroes} from "@/helpers/functions/number";

export default defineComponent({
  name: "InputCnkList",
  components: {ProductSelector, InputTextEditor},
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: [] as string[],
    },
    errors: {
      type: Object,
      required: false,
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      internalModel: '<p>' + this.modelValue.join(', ') + '</p>',
    };
  },
  watch: {
    modelValue: function (newVal) {
      this.internalModel = '<p>' + newVal.join(', ') + '</p>';
    },
  },
  methods: {
    onBlur() {
      const result = convertProductCodesListToArray(this.internalModel);
      if (!productCodesArrayIsValid(result)) {
        // throw [new DpException(useI18n().t('exceptionOnlyNumbersCnk')]);
        throw [new DpException('Een CNK code mag enkel getallen bevatten.')];
      }
      this.$emit('update:modelValue', result);
    },
    addIncludedProducts(selectedProducts: CatalogProductOverviewRestDto[]): void {
      if (!selectedProducts?.length) {
        return;
      }
      let newCnkCodes: string[] = [...this.modelValue] as string[];
      for (const product of selectedProducts) {
        if (product.product_info.cnk_code) {
          const trimmedCnk = trimLeadingZeroes(product.product_info.cnk_code);
          if (!newCnkCodes.includes(trimmedCnk)) {
            newCnkCodes.push(trimmedCnk);
          }
        }
      }
      this.$emit('update:modelValue', newCnkCodes);
    },
  },
});
