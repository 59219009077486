import {SynchronizeSupportedDeliveryMethodsRestDto} from "@/api/pharma-order-settings/models";

export class SynchronizeSupportedDeliveryMethodsRestDtoModel implements SynchronizeSupportedDeliveryMethodsRestDto {
    supported_method_codes: string[];

    constructor() {
        this.supported_method_codes = [];
    }

    public static createWithDefaults(): SynchronizeSupportedDeliveryMethodsRestDtoModel {
        return new SynchronizeSupportedDeliveryMethodsRestDtoModel();
    }
}
