import {Service} from "@/services/Service";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";

export class CacheService extends Service {

    private cache = new Map<string, any>();

    protected constructor() {
        super();
    }

    public get(key: CacheKeyEnum|string, customerCode: string|null = null): any {
        const item: CachedItem | null = this.cache.get((customerCode ?? this.getCustomerCode()) + '.' + key);
        if (!item) {
            return null;
        }

        if (item.expirationTine === -1 || item.expirationTine > Date.now()) {
            return item.value;
        }

        this.invalidate(key, customerCode);
        return null;
    }

    public set(
        key: CacheKeyEnum|string,
        value: any,
        customerCode: string|null = null,
        expiration: number = -1
    ): any {
        const expirationTime = (expiration > -1)
            ? Date.now() + expiration
            : expiration;
        this.cache.set(
            (customerCode ?? this.getCustomerCode()) + '.' + key,
            {
                value: value,
                expirationTine: expirationTime
            }
        );
    }

    public invalidate(key: CacheKeyEnum|string, customerCode: string|null = null): void {
        this.cache.set((customerCode ?? this.getCustomerCode()) + '.' + key, null);
    }

}

interface CachedItem {
    value: any,
    expirationTine: number
}
