import {RestService} from "@/services/rest/RestService";
import {OrderSettingsAPIApi} from "@/api/pharma-order-settings";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {
    SynchronizeSupportedDeliveryMethodsRestDtoModel
} from "@/models/api/pharma-order-settings/SynchronizeSupportedDeliveryMethodsRestDtoModel";
import {
    CodeDescriptionRestDto,
    DeliveryCostRuleOverviewRestDto,
    DeliveryCostRuleRestDto
} from "@/api/pharma-order-settings/models";

export class DeliveryMethodsRestService extends RestService {

    private apiService: OrderSettingsAPIApi;

    protected constructor() {
        super();

        this.apiService = new OrderSettingsAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().orderUrl);
    }

    public async findAuthorizedFlowDeliveryMethods(): Promise<CodeDescriptionRestDtoModel[]> {
        return this.findAuthorizedDeliveryMethods(true, null);
    }

    public async findAuthorizedOrderDeliveryMethods(): Promise<CodeDescriptionRestDtoModel[]> {
        return this.findAuthorizedDeliveryMethods(null, true);
    }

    private async findAuthorizedDeliveryMethods(filter_is_allowed_for_flow?: boolean|null, filter_is_allowed_for_order?: boolean|null): Promise<CodeDescriptionRestDtoModel[]> {
        const response = await this.apiService.findAuthorizedDeliveryMethods(null, filter_is_allowed_for_flow, filter_is_allowed_for_order);

        return response.data.results;
    }

    public async findSupportedDeliveryMethodCodes(): Promise<string[]> {
        const response = await this.findSupportedDeliveryMethods();

        const result = [] as string[];
        for (const obj of response) {
            result.push(obj.code);
        }

        return result;
    }

    public async findSupportedDeliveryMethods(): Promise<CodeDescriptionRestDto[]> {
        const response = await this.apiService.findSupportedDeliveryMethods(this.getPharmacyCode());

        const result = [] as CodeDescriptionRestDto[];
        for (const obj of response.data.results) {
            result.push(obj);
        }

        return result;
    }

    public async synchronizeSupportedDeliveryMethods(supported_method_codes: string[]): Promise<void> {
        const dto = new SynchronizeSupportedDeliveryMethodsRestDtoModel();
        dto.supported_method_codes = supported_method_codes;

        await this.apiService.synchronizeSupportedDeliveryMethods(dto, this.getPharmacyCode());
    }


    public async findDeliveryCostRules(): Promise<DeliveryCostRuleOverviewRestDto[]> {
        const response = await this.apiService.findDeliveryCostRules(this.getPharmacyCode());

        return response.data.results;
    }

    public async getDeliveryCostRule(id: string): Promise<DeliveryCostRuleRestDto> {
        const response = await this.apiService.getDeliveryCostRule(this.getPharmacyCode(), id);

        return response.data;
    }

    public async addDeliveryCostRule(body: DeliveryCostRuleRestDto): Promise<void> {
        await this.apiService.addDeliveryCostRule(body, this.getPharmacyCode());
   }

    public async updateDeliveryCostRule(body: DeliveryCostRuleRestDto, id: string): Promise<void> {
        await this.apiService.updateDeliveryCostRule(body, this.getPharmacyCode(), id);
   }

    public async deleteDeliveryCostRule(id: string): Promise<void> {
        await this.apiService.deleteDeliveryCostRule(this.getPharmacyCode(), id);
   }

}
