<template>
  <div
    v-if="visible"
    ref="panel"
    :class="panelClass"
    :style="{ top: `${panelPosition.top}px`, left: `${panelPosition.left}px`, maxHeight: `${maxHeight}px`, overflowY: 'auto'}"
  >
    <slot></slot>
  </div>
</template>

<script>

import {nextTick} from "vue";

export default {
  name: "FloatingPanel",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
      required: false,
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      panelPosition: {
        top: 0,
        left: 0,
      },
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.updatePanelPosition();
      }
    },
  },
  methods: {
    async updatePanelPosition() {
      await nextTick(); // Ensure DOM updates are applied
      let left = 0;
      let top = 0;
      const parentPanel = this.$el.parentElement;
      const panel = this.$el;
      // Get panel width to calculate positioning
      const panelWidth = panel.offsetWidth || 0;
      const viewportWidth = window.innerWidth;

      if (this.position) {
        const targetRect = parentPanel.getBoundingClientRect();
        // Calculate initial position
        left = this.position.x;
        top = this.position.y;

        // Adjust if it overflows the viewport
        if (left + panelWidth + targetRect.left > viewportWidth) {
          left = viewportWidth - (panelWidth + targetRect.left + 30);
        }
      } else {
        const viewportHeight = window.innerHeight;
        const panelHeight = panel.offsetHeight || 0;
        left = (viewportWidth - panelWidth) / 2;
        top = (viewportHeight - panelHeight) / 2;
      }
      this.panelPosition = {top, left};
    },
  },
  mounted() {
    window.addEventListener("resize", this.updatePanelPosition);
    this.updatePanelPosition();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updatePanelPosition);
  },
  computed: {
    panelClass() {
      if (this.position) {
        return 'floating-panel absolute-positioned';
      }

      return 'floating-panel center-positioned';
    }
  }
};
</script>

<style>
.floating-panel {
  position: absolute;
  z-index: 2000;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: max-content;
}

.center-positioned {
  position: fixed;
}

.absolute-positioned {
  position: fixed;
}
</style>
