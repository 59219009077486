
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {AbilityContext} from "@/context/AbilityContext";
import {DeliveryCostRuleRestDtoModel} from "@/models/api/pharma-order-settings/DeliveryCostRuleRestDtoModel";
import {DeliveryMethodsRestService} from "@/services/rest/settings/DeliveryMethodsRestService";
import {CodeDescriptionRestDto} from "@/api/pharma-order-settings/models";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {EnumClassNameOrderEnum} from "@/models/enum/EnumClassNameOrderEnum";

export default defineComponent({
  name: "DeliveryCostRuleDetail",
  components: {
    BaseContainer,
    BaseTitle,
    AlertError2,
    BaseSpinner,
  },
  props: {
    mode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      detailModel: DeliveryCostRuleRestDtoModel.createWithDefaults(),
      authorizedMethods: [] as CodeDescriptionRestDto[],
      allCountries: [] as CodeDescriptionRestDto[],

      savedSuccessfully: false as boolean,
      toaster: useToast(),
      returnRouteObject: {name: 'delivery-cost-rules'} as object,
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
    AbilityContext() {
      return AbilityContext;
    },
    countriesForDropdown(): CodeDescriptionRestDto[] {
      return [
        {
          code: '',
          description: '',
        },
        ...this.allCountries
      ];
    }
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        this.authorizedMethods = await DeliveryMethodsRestService.getInstance().findAuthorizedOrderDeliveryMethods();
        this.allCountries = await EnumRestService.getInstance().getOrderEnumValuesByClassname(EnumClassNameOrderEnum.COUNTRY);
        if (this.mode === 'new') {
          for (const method of this.authorizedMethods) {
            if (method.code === 'DELIVERY_BY_SHIPPING') {
              // If DELIVERY_BY_SHIPPING is between the options, it is the default
              this.detailModel.delivery_method_code = method.code;
            }
          }
        } else {
          this.detailModel = await DeliveryMethodsRestService.getInstance().getDeliveryCostRule(this.$route.params.id);
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (this.detailModel.country_code === "") {
          // Replace the default empty value of the dropdown.
          this.detailModel.country_code = null;
        }
        if (this.detailModel.postal_code === "") {
          this.detailModel.postal_code = null;
        }
        if (this.mode === 'new') {
          await DeliveryMethodsRestService.getInstance().addDeliveryCostRule(this.detailModel);
        } else {
          await DeliveryMethodsRestService.getInstance().updateDeliveryCostRule(this.detailModel, this.$route.params.id);
        }
        await this.afterSave();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.pageUI.setReady();
      }
    },
    async afterSave() {
      await this.$router.push(this.returnRouteObject);
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
