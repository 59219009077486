
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {LibraryOverviewRestDto} from "@/api/pharma-cms-content-block/models";
import CbLibraryOverview from "@/components/layouts/content-pages/wysiwyg/libraries/CbLibraryOverview.vue";
import {TinyEmitter} from "tiny-emitter";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import ContentBlockTypesWithLibrary from "@/models/rules/ContentBlockTypesWithLibrary";
import {generateDomId} from "@/helpers/functions/string";

export default defineComponent({
  name: "ButtonCbLibraries",
  emits: ["exceptions"],
  components: {CbLibraryOverview},
  props: {
    cbType: {
      type: String,
      required: true
    },
    bus: {
      type: TinyEmitter,
      required: true,
    },
    title: {
      type: String,
      required: false
    },
    class: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isReady: false as boolean,
      modalId: generateDomId('cbLibrariesModal-') as string,
      tabId: generateDomId() as string,
    };
  },
  watch: {
    cbType: {
      async handler() {
        await this.reloadContent();
      },
      immediate: true
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ['getContentBlockLibsForType']),
    contentBlockLibsForType(): LibraryOverviewRestDto[] {
      return this.getContentBlockLibsForType(this.cbType);
    },
    contentBlockTypesWithLibrary(): ContentBlockTypesEnum[] {
      return ContentBlockTypesWithLibrary;
    }
  },
  methods: {
    ...mapActions('cms_content_block', ['searchContentBlockLibsForType']),
    targetId(targetCode: string): string {
        return this.tabId + '_' + targetCode;
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;

      try {
        const cbType = this.cbType as ContentBlockTypesEnum;
        if (this.contentBlockTypesWithLibrary.includes(cbType)) {
          await this.searchContentBlockLibsForType({type: this.cbType});
        }
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }

      this.isReady = true;
    },
  }
})
